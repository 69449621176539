import { Box, CircularProgress, circularProgressClasses, CircularProgressProps } from "@mui/material";
import { Fragment } from "react";

export default function LoadingData({ size }: { size?: number }) {
    const sizes = size || 16;
    return (
        <Fragment>
            <Box
                sx={{
                    width: '100%',
                    height: '20vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress size={sizes} />
            </Box>
        </Fragment>
    );
}

export function FacebookCircularProgress(props: CircularProgressProps) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={32}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
            animationDuration: '750ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={32}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }