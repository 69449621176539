/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type LanguageProps = {
    key: string,
    label: string,
    dir: string,
    active: boolean,
    icon: any,
}
type LanguageMap = {
    en: LanguageProps,
    vn: LanguageProps,
}

const languageMap: LanguageMap = {
    en: { key: "English", label: "English", dir: "ltr", active: true, icon: '/assets/icons/flag-en.png' },
    vn: { key: "Tiếng Việt", label: "Tiếng Việt", dir: "ltr", active: false, icon: '/assets/icons/flag-vi.png' }
};

export default function LanguageSelect() {

    const selected = localStorage.getItem("i18nextLng") || "vn";
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <>
            <Box
                onClick={() => setOpen(true)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    pt: 2,
                    pb: 2,
                    px: 1.6,
                    '& :hover': {
                        background: 'rgba(22, 27, 46, 0.4)',
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >

                    <Box
                        sx={{
                            width: 20,
                            height: 20,
                            minWidth: 20,
                            '& img': {
                                width: '100%',
                            },
                            marginRight: 1.1,
                        }}
                    >
                        <img src={languageMap[selected].icon} alt=""/>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: 16,
                            color: 'inherit',
                            fontWeight: 500,
                            marginRight: '3rem',
                        }}
                        noWrap
                    >
                        {t('Language')}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        width: 24,
                        height: 24,
                        minWidth: 24,
                        '& img': {
                            width: '100%',
                        },
                        transform: open?'rotate(90deg)':'none',
                    }}
                >
                    {<img src='/assets/icons/icon-next.svg' alt="" />}
                </Box>
            </Box>

            <Box px={1.8}>
                {open && Object.keys(languageMap)?.map(item => (
                    <Box key={item}
                        onClick={() => {
                            i18next.changeLanguage(item);
                            localStorage.setItem("i18nextLng", item)
                            setOpen(false)
                        }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            pt: 1.3,
                            pb: 1.3,
                            px: 1.6,
                            mb: 1,
                            '& :hover': {
                                background: 'rgba(22, 27, 46, 0.4)',
                            },
                            background: '#242B43',
                            borderRadius: '8px',
                            border: '1px solid #6C34FF',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >

                            <Box
                                sx={{
                                    width: 20,
                                    height: 20,
                                    minWidth: 20,
                                    '& img': {
                                        width: '100%',
                                    },
                                    marginRight: 1.1,
                                }}
                            >
                                <img src={languageMap[item].icon} alt=""/>
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    color: 'inherit',
                                    fontWeight: 500,
                                    marginRight: '3rem',
                                }}
                                noWrap
                            >
                                {languageMap[item].label}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: item=== selected? 'block': 'none',
                                width: 24,
                                height: 24,
                                minWidth: 24,
                                '& img': {
                                    width: '100%',
                                },
                            }}
                        >
                            {<img src='/assets/icons/icon-check.svg' alt="" />}
                        </Box>
                    </Box>
                ))}
            </Box>

        </>
    );
}