import { Box, Button, IconButton, InputBase } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { updateSearchGroup } from "components/user/userSlice";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

export default function SearchBar({ onClose }: { onClose: () => void }) {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useAppDispatch();

    const handleSearch = (v: string) => {
        setSearchValue(v)
        let value = v.trim();
        if(value.length > 1){
            dispatch(updateSearchGroup(value));
        }else{
            dispatch(updateSearchGroup(''))
        }
    }

    return (
        <Fragment>
            <Box
                sx={{
                    background: '#1F2C44',
                    borderRadius: '50px',
                    height: 35,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    px: 1,
                }}
            >
                <img src="/assets/icons/icon-search-colored.svg" alt="" />
                <InputBase fullWidth
                    placeholder="Search ..."
                    value={searchValue}
                    onChange={(e) => handleSearch(e.target.value)}
                    sx={{
                        marginLeft: .8,
                    }}
                />
                <IconButton
                    onClick={() => handleSearch('')}
                >
                    <img src="/assets/icons/icon-delete.svg" alt="" />
                </IconButton>
            </Box>
            <Button variant="text"
                onClick={onClose}
                sx={{
                    textTransform: 'capitalize'
                }}
            >
                {t('Cancel')}
            </Button>
        </Fragment>
    );
}