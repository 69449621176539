/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { updateUnreadNoti, userSelector } from "components/user/userSlice";
import ApiMobile from "libs/apiMobile";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Notifications() {

    const iconNoti = "/assets/icons/icon-noti.svg";
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, isLoggedIn, unReadNoti } = useAppSelector(userSelector);
    const api = new ApiMobile();


    const getData = async (limit, offset, token) => {
        try {
            await api.getNotificationList(limit, offset, token).then(
                (result) => {
                    const unreadTotal = result.data.length > 0 ? result.data.filter(v => v.is_read === 0).length : 0;
                    dispatch(updateUnreadNoti(unreadTotal));
                },
                (error) => {
                    console.log(error!.message);
                }
            )
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            getData(10, 0, user.token);
        }
    }, [])


    const handleOpen = () => {
        navigate(`/notification`);
    }

    return (
        <Fragment>
            <Box
                onClick={handleOpen}
                sx={{
                    position: 'relative',
                    p: .5,
                    marginRight: 2,
                    width: 30,
                    minWidth: 30,
                    height: 30,
                    'img': {
                        height: '100%',
                        objectFit: 'contain'
                    },
                    cursor: 'pointer',
                }}
            >
                <img src={iconNoti} alt="" />
                <Box
                    sx={{
                        display: unReadNoti > 0 ? 'block' : 'none',
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        width: 5,
                        minWidth: 5,
                        height: 5,
                        borderRadius: '50%',
                        background: '#FFE371',
                        boxShadow: '0px 0px 3px #FFE371',
                    }}
                >

                </Box>
            </Box>
        </Fragment>
    );
}