import { Box, Typography } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { logout } from "components/user/userSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onLogoutButtonClick = (e) => {
        e.preventDefault()

        dispatch(logout())
        navigate("/")
    }

    return (
        <Box
            onClick={onLogoutButtonClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                pt: 2,
                pb: 2,
                px: 1.6,
                '& :hover': {
                    background: 'rgba(22, 27, 46, 0.4)',
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >

                <Box
                    sx={{
                        width: 20,
                        height: 20,
                        minWidth: 20,
                        '& img': {
                            width: '100%',
                        },
                        marginRight: 1.1,
                    }}
                >
                    <img src='/assets/icons/icon-logout.svg' alt="" />
                </Box>
                <Typography
                    sx={{
                        fontSize: 16,
                        color: 'inherit',
                        fontWeight: 500,
                        marginRight: '3rem',
                    }}
                    noWrap
                >
                    {t('Logout')}
                </Typography>
            </Box>

            <Box
                sx={{
                    width: 24,
                    height: 24,
                    minWidth: 24,
                    '& img': {
                        width: '100%',
                    },
                }}
            >
                {<img src='/assets/icons/icon-next.svg' alt="" />}
            </Box>
        </Box>
    );
}