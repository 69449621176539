/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, ButtonBase, CircularProgress, styled, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import LoadingData, { FacebookCircularProgress } from "components/Loading/Spinner";
import { checkAuthenticated, userSelector } from "components/user/userSlice";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconEdit } from './IconEdit.svg';

const Input = styled('input')({
    display: 'none',
});

export function BtnEditCover() {
    const { t } = useTranslation();

    const { user } = useAppSelector(userSelector);
    const dispatch = useAppDispatch();
    const [pending, setPending] = useState(false);

    const changeHandler = async (event) => {

        console.log("file upload", event.target.files[0])
        setPending(true)

        if (event.target.files[0] !== undefined) {

            var selectedFile = new File([event.target.files[0]], "image.jpg");

            const formData = new FormData();

            formData.append('file', selectedFile);

            await fetch(
                `${process.env.REACT_APP_API}/user/update/cover`,
                {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + user.token
                    }),
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log('Success:', result);
                    dispatch(checkAuthenticated())
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
        setPending(false)
    };
    return (
        <Fragment>
            <Box
                sx={{
                    position: 'absolute',
                    top: 12,
                    right: 12,
                }}
            >
                {pending ?
                    <FacebookCircularProgress />
                    :
                    <label htmlFor="icon-button-file">
                        <Input accept="image/*" id="icon-button-file" type="file"
                            onChange={changeHandler}
                        />
                        <ButtonBase component="span">
                            <Box
                                sx={{
                                    height: 32,
                                    px: 1.2,
                                    borderRadius: '166px',
                                    background: 'rgba(22, 27, 46, 0.5)',
                                    fontSize: 13,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '& svg': {
                                        marginRight: .6,
                                    }
                                }}
                            >
                                <IconEdit />
                                <Typography>
                                    {t("Edit")}
                                </Typography>
                            </Box>
                        </ButtonBase>
                    </label>
                }

            </Box>
        </Fragment>
    );
}

export function BtnEditAvatar() {

    const { user } = useAppSelector(userSelector);
    const dispatch = useAppDispatch();
    const [pending, setPending] = useState(false);

    const changeHandler = async (event) => {

        console.log("file upload", event.target.files[0])
        setPending(true)

        if (event.target.files[0] !== undefined) {

            var selectedFile = new File([event.target.files[0]], "image.jpg");

            const formData = new FormData();

            formData.append('file', selectedFile);

            await fetch(
                `${process.env.REACT_APP_SING_MEDIA_API}/avatar`,
                {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + user.token
                    }),
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log('Success:', result);
                    dispatch(checkAuthenticated())
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
        setPending(false)
    };

    return (
        <Fragment>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                }}
            >
                {pending ?
                    <FacebookCircularProgress />
                    :
                    <label htmlFor="icon-button-file-1">
                        <Input accept="image/*" id="icon-button-file-1" type="file"
                            onChange={changeHandler}
                        />
                        <ButtonBase component="span">
                            <Box
                                sx={{
                                    height: 32,
                                    width: 32,
                                    minWidth: 32,
                                    borderRadius: '50%',
                                    border: '1px solid #BF26E5',
                                    background: '#1E2336',
                                    fontSize: 13,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '& svg': {
                                        width: 12,
                                    }

                                }}
                            >
                                <IconEdit />
                            </Box>
                        </ButtonBase>
                    </label>
                }
            </Box>
        </Fragment>
    );
}