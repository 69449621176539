import { ButtonBase, styled } from "@mui/material";

export const ButtonImport = styled(ButtonBase)({
    '&.MuiButtonBase-root': {
        width: '100%',
        height: 40,
        background: '#273B60',
        borderRadius: '8px',
        border: '1px solid #5371D7',
        color: '#A1B6FF',
        fontSize: 14,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    '& img': {
        width: 17,
        height: 17,
        objectFit: 'cover',
        marginRight: '7px',
    },
    '&:hover': {
        background: '#1F2C44',
    }
});


export const ButtonGradient = styled(ButtonBase)({
    '&.MuiButtonBase-root': {
        width: '100%',
        margin: '8px',
        height: 48,
        background: 'linear-gradient(96.84deg, #BF26E5 4.07%, #3C14DA 94.64%)',
        borderRadius: '80px',
        color: '#fff',
        fontSize: 14,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        textTransform: 'uppercase'
    },
    '& img': {
        width: 17,
        height: 17,
        objectFit: 'cover',
        marginRight: '7px',
    },
    '&:hover': {
        background: '#1F2C44',
    }
});

