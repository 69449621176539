import React, { useEffect } from 'react';
import './App.css';

import { HelmetProvider } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks'
import { mainRoutes } from './config/router';
import MainLayout from 'components/layout/MainLayout';
import { checkAuthenticated, config, userSelector } from 'components/user/userSlice';
import LoginPage from 'components/Login';
import { Container } from '@mui/material';
import theme from 'theme';

function App() {
  const { isLoggedIn } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuthenticated())
    dispatch(config());
  }, [dispatch]);

  const routing = useRoutes(mainRoutes);

  return (
    <HelmetProvider>
      <MainLayout />
      <Container maxWidth="xs"
        sx={{
          pt: 2,
          p: '0 !important',
          background: theme.palette.background.default,
          minHeight: 'calc(100vh - 70px)',
          //border: '1px solid #fff',
          boxShadow: '0px 6px 10px #100E18',
        }}
      >
        {isLoggedIn ? routing
          :
          <LoginPage />
        }
      </Container>
    </HelmetProvider>
  );
}

export default App;