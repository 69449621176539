import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import API from 'libs/api';
import ApiMobile from 'libs/apiMobile';
import { UserProfile } from 'types/types';
import { isFacebookApp } from 'utils/utils';
import type { RootState } from '../../app/store'

const windowMode = isFacebookApp() ? 'REDIRECT' : 'POPUP';

export const checkAuthenticated = createAsyncThunk('user/check', async (_arg, thunkApi) => {
    let profile: UserProfile | null = null
    try {
        let authResult = await window['singConnect']['checkAuthenticated']({windowMode: windowMode})
        let auth: any | null = null
        if (authResult.isAuthenticated) {
            let token = ""
            authResult.authenticated(function (a: any) {
                token = a.token
                auth = a
            })
                .notAuthenticated((_auth) => {
                    profile = null
                });
            if (auth != null) {
                const api = new API()
                profile = await api.authCheck(token)
                if (profile != null) {
                    profile.token = token
                }
            }
        }

        if (profile) {
            return profile
        } else {
            return thunkApi.rejectWithValue(profile)
        }
    } catch (e) {
        return thunkApi.rejectWithValue(e);
    }
}
);

export const login = createAsyncThunk('user/login', async (_arg, thunkApi) => {
    let idpHint = ''
    let authenticationOptions: any = {windowMode: windowMode};
    if (idpHint) {
        authenticationOptions.idpHint = idpHint;
    }

    try {
        let account = await window['singConnect']['authenticate'](authenticationOptions)
        if (account) {
            const api = new API()
            const token = account.auth.token
            let profile = await api.authCheck(token)
            profile.token = token
            return profile
        } else {
            return thunkApi.rejectWithValue(null);
        }

    } catch (e) {
        console.log('error login', e)
        return thunkApi.rejectWithValue(e);
    }
}
);


export const logout = createAsyncThunk('user/logout', async (_arg, thunkApi) => {
    try {
        await window['singConnect'].logout({windowMode: windowMode})
        return true
    } catch (e) {
        console.log('error login', e)
        return thunkApi.rejectWithValue(e);
    }
}
);

export const signup = createAsyncThunk('user/isAdmin', async (_arg, thunkApi) => {
    try {

    } catch (e) {
        console.log('error signup', e)
        return thunkApi.rejectWithValue(e);
    }
}
);

export const config = createAsyncThunk('user/config', async (_arg, thunkApi) => {

    try {
        const api = new ApiMobile();
        const rs = await api.getAppConfig('android', 1);
        if (rs) {
            return rs.data;
        } else {
            return thunkApi.rejectWithValue(null);
        }

    } catch (e) {
        console.log('error fetch config', e)
        return thunkApi.rejectWithValue(e);
    }
}
);

type UsersState = {
    user: UserProfile
    isLoading: boolean
    isLoggedIn: boolean
    isUpdatePin: string
    config: any
    isSearchGroup: string
    unReadNoti: number,
}

const initialState: UsersState = {
    user: {
        user_id: "",
        email_verified: false,
        nickname: "",
        email: "",
        first_name: "",
        last_name: "",
        username: "",
        name: "",
        type: 0,
        hasMasterPin: true,
        token: "",
        avatar: "",
        cover: "",
        verified: false,
        googleId: "",
        facebookId: ""
    },
    isLoading: false,
    isLoggedIn: false,
    isUpdatePin: '',
    config: null,
    isSearchGroup: '',
    unReadNoti: 0,
}

function saveSession(user: UserProfile) {
    localStorage.setItem('user_fanclub', JSON.stringify(user));
    localStorage.setItem('user_time_fanclub', new Date().getTime().toString())
}
function removeSession() {
    localStorage.removeItem('user_fanclub');
    localStorage.removeItem('user_time_fanclub')
}

function checkSession(): UserProfile | null {
    let user = localStorage.getItem('user_fanclub')
    let time = localStorage.getItem('user_time_fanclub')
    if (!user || !time) {
        return null
    }
    let currentTime = new Date().getTime()
    if (currentTime - Number(time) >= 24 * 60 * 60 * 1000) {
        removeSession()
        return null
    }
    let profile = JSON.parse(user) as UserProfile
    return profile
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isLoggedIn = false;
            return state;
        },
        updatePin: (state, action: PayloadAction<string>) => {
            state.isUpdatePin = action.payload;
        },
        updateConfig: (state, action: PayloadAction<any>) => {
            state.config = action.payload;
        },
        updateSearchGroup: (state, action: PayloadAction<string>) => {
            state.isSearchGroup = action.payload;
        },
        updateUnreadNoti: (state, action: PayloadAction<number>) => {
            state.unReadNoti = action.payload;
        }

    },
    extraReducers: (builder) => {
        //check auth
        builder.addCase(checkAuthenticated.fulfilled, (state, { payload }) => {
            if (payload) {
                state.user = payload
                state.user.user_id = payload.user_id
                saveSession(state.user)
                state.isLoading = false;
                state.isLoggedIn = true;
            }

            console.log('state', state)
        })
        builder.addCase(checkAuthenticated.rejected, (state, _action) => {
            removeSession()
            state.isLoading = false;
            state.isLoggedIn = false;
        })
        builder.addCase(checkAuthenticated.pending, (state, _action) => {
            //check local storage first
            let savedUser = checkSession()
            if (savedUser) {
                state.isLoggedIn = true;
                state.user = savedUser
            }
            state.isLoading = true;
        })

        //login
        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.user = payload
            state.user.user_id = payload.user_id
            saveSession(state.user)
            state.isLoading = false;
            state.isLoggedIn = true;
        })
        builder.addCase(login.rejected, (state, _action) => {
            removeSession()
            state.isLoading = false;
            state.isLoggedIn = false;
        })
        builder.addCase(login.pending, (state, _action) => {
            state.isLoading = true;
        })

        //logout
        builder.addCase(logout.fulfilled, (state, { payload }) => {
            removeSession()
            state.isLoading = false;
            state.isLoggedIn = false;
        })
        builder.addCase(logout.rejected, (state, action) => {
            removeSession()
            state.isLoading = false;
            state.isLoggedIn = false;
        })
        builder.addCase(logout.pending, (state, action) => {
            state.isLoading = true;
        })
        //signup
        builder.addCase(signup.fulfilled, (state, { payload }) => {
            removeSession()
            state.isLoading = false;
            state.isLoggedIn = false;
        })

        //config
        builder.addCase(config.fulfilled, (state, { payload }) => {
            state.config = payload;
        })
        builder.addCase(config.rejected, (state, action) => {
            state.config = null;
        })
        builder.addCase(config.pending, (state, action) => {
            state.config = null;
        })
    },
});



export const {
    clearState,
    updatePin,
    updateConfig,
    updateSearchGroup,
    updateUnreadNoti
} = userSlice.actions;
export const userSelector = (state: RootState) => state.user;
