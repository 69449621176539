export type MenuItem = {
    icon?: string,
    label: string,
    link: string,
}

export const menuConfig: MenuItem[] =
    [
        {
            label: 'Home',
            link: '/'
        },

    ]


    export const menuAccountConfig: MenuItem[] =
    [
        {
            icon: '/assets/icons/icon-edit.svg',
            label: 'Edit Profile',
            link: '/profile/personal-info'
        },
        {
            icon: '/assets/icons/icon-password.svg',
            label: 'Change Password',
            link: '/profile/update-password'
        },
        {
            icon: '/assets/icons/icon-email.svg',
            label: 'Change Email',
            link: '/profile/update-email'
        },

    ]
