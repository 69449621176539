/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import { useAppSelector } from 'app/hooks'
import { userSelector } from './userSlice';
import { Button, Container, SwipeableDrawer } from '@mui/material';
import { MenuMobile } from 'components/NavBar/components/TopMenuMobile';


export default function LoginButton() {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);


    const { user, isLoading, isLoggedIn } = useAppSelector(
        userSelector
    );


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {

        setOpen(false);
    };


    return (
        <React.Fragment>

            {!isLoading && isLoggedIn &&
                <div>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        sx={{
                            '& img': {
                                width: 40,
                                minWidth: 40,
                                height: 40,
                                borderRadius: '50%',
                                overflow: 'hidden',
                            }
                        }}
                    >
                        <img className="avatar-header" src={user.avatar ? user.avatar : '/img/default-avatar.jpg'} alt="Profile" />
                    </Button>
                    <SwipeableDrawer
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={handleClose}
                        anchor='right'
                        sx={{
                            '& .MuiDrawer-paper':{
                                background: '#1E2336',
                            }
                        }}
                    >
                        <Container maxWidth="xs"
                            sx={{
                                px: '0 !important',
                            }}
                        >
                            <MenuMobile onClose={handleClose} />
                        </Container>

                    </SwipeableDrawer>
                </div>
            }

        </React.Fragment>

    );
}
