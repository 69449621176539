import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import SuspenseLoader from '../components/SuspenseWithChunkError/index';
import BaseLayout from 'components/layout/MainLayout/BaseLayout';
//import BaseLayout from 'components/layout/MainLayout/BaseLayout';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const PageNotFoundView = Loader(lazy(() => import('../components/404')));

const Home = Loader(lazy(() => import('../pages/Home')));
const ClubDetails = Loader(lazy(() => import('../pages/ClubDetails')));
const PostDetails = Loader(lazy(() => import('../pages/PostDetails')));
const EditProfile = Loader(lazy(() => import('../pages/Profile/components/PersonalInfo')));
const ChangePassword = Loader(lazy(() => import('../pages/Profile/components/UpdatePassword')));
const UpdateEmail = Loader(lazy(() => import('../pages/Profile/components/UpdateEmail')));
const DeleteAccount = Loader(lazy(() => import('../pages/Profile/components/DeleteAccount')));

const TopFans = Loader(lazy(() => import('../pages/TopFan')));
const Notification = Loader(lazy(() => import('../components/Notifications/components/NotificationList')));



export const mainRoutes: PartialRouteObject[] = [
  {
    path: '*',
    element: <Navigate to='404' />
  },
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/notification',
    element: <Notification />,
  },
  {
    path: '/group/:id',
    element: <ClubDetails />,
  },
  {
    path: 'post/:postId',
    element: <PostDetails />,
  },
  {
    path: '/group/:id/topfans',
    element: <TopFans />,
  },
  {
    path: 'profile',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: (<Navigate to='personal-info' replace />),
      },
      {
        path: 'personal-info',
        element: <EditProfile />
      },
      {
        path: 'update-password',
        element: <ChangePassword />
      },
      {
        path: 'update-email',
        element: <UpdateEmail />
      },
      {
        path: 'delete-account',
        element: <DeleteAccount />
      }

    ]
  },
  {
    path: '404',
    element: <PageNotFoundView />
  },

]
