import { UserProfile } from 'types/types';
import { xLangConvert } from 'utils/utils';
import APIBase from './apiBase';


export default class API extends APIBase {
    /**
     * Page size to use for fetching orders
     */
    public pageSize = 20
    public xLang = localStorage.getItem("i18nextLng") || "en";
    /**
     * Write APIs function bellow
     * 
     */

    
    public async authCheck(token: string): Promise<UserProfile> {
        const result = await this.post(
            `/auth/check`, {
        }, {},
            {
                'Authorization': 'Bearer ' + token
            }
        )
        console.log("auth/check start")
        return result as UserProfile;
    }

    // ACCOUNT 

    public async updateUserInfo(firstName, lastName, token: string): Promise<any> {
        const result = await this.post(
            `/user/update`,
            {
                first_name: firstName,
                last_name: lastName,
            }, {},
            {
                'Authorization': 'Bearer ' + token
            }
        )
        return result
    }

    public async updatePassword(pass, oldPass, token: string): Promise<any> {
        const result = await this.post(
            `/user/change-pass`,
            {
                password: pass,
                old_password: oldPass,
            }, {},
            {
                'Authorization': 'Bearer ' + token,
                'x-lang': xLangConvert(this.xLang),
            }
        )
        return result
    }

    public async updateEmail(email, token: string): Promise<any> {
        const result = await this.post(
            `/user/update-email`,
            {
                email: email
            }, {},
            {
                'Authorization': 'Bearer ' + token
            }
        )
        return result
    }

    public async verifyEmail(t, i): Promise<any> {
        const result = await this.post(
            `/user/update-email/confirm`,
            {
                token: t,
                iv: i,
            }
        )
        return result
    }
    public async deleteAccount(pass, token: string): Promise<any> {
        const result = await this.post(
            `/user/delete`,
            {
                password: pass
            }, {},
            {
                'Authorization': 'Bearer ' + token
            }
        )
        return result
    }
}