import { Member } from "types/forum";

export const limitText = (text: string, num: number) => {
    if (!text || !num) {
        return '';
    }
    return text.slice(0, num) + '...'
}

export const xLangConvert = (lang) => {
    if (lang === 'vn') {
        return 'vi';
    }
    return lang
}

export function validEmail(input: string) {

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(input)) {

        return false;
    }
    return true;

}

export const secureEmail = (email: string) => {
    if (!email || !email.includes('@')) {
        return email;
    }
    let username = email.split('@')[0];
    let domain = email.split('@')[1];
    username = username.slice(0, Math.floor(username.length / 2)) + '*'.repeat(username.length - Math.floor(username.length / 2));
    return username + "@" + domain;
}

export const getFullNameMember = (item: Member | null) => {
    if (!item) {
        return '';
    }
    /* const fullName = item.first_name + ' ' + item.last_name;
    if (fullName.length > 1) {
        return fullName;
    } */
    return secureEmail(item.username);
}

export function isFacebookApp() {
    let is_webview = navigator.userAgent.toLowerCase().match(/android.*applewebkit(?=.*version)/)
    let is_uiwebview = navigator.userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios))/)
    console.log('isWebview', is_webview);
    console.log('isUiWebview', is_uiwebview);
    return (is_uiwebview || is_webview)? true : false;
}