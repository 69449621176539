import { Comment } from 'types/comment';
import { Datum, Member } from 'types/forum';
import { NotiInterface } from 'types/noti';
import { Post } from 'types/post';
import { APIConfig, APIResponse } from 'types/types';
import { xLangConvert } from 'utils/utils';
import APIBase from './apiBase';

export default class ApiMobile extends APIBase {
    constructor(config?: APIConfig, logger?: (arg: string) => void) {
        if (!config) {
            config = {
                apiUrl: process.env.REACT_APP_MOBILE_API || 'https://mobile-dev.singsing.net'
            }
        }

        super(config, logger)
    }

    public xLang = localStorage.getItem("i18nextLng") || "en";

    public async getAppConfig(os?, version?): Promise<any> {
        const result = await this.get(
            `/app/config`,
            {
                os: os,
                version: version,
            }
        )
        return result;
    }

    public async getListForum(offset: number, limit: number, keyword, token: string): Promise<APIResponse<Datum[]>> {
        const result = await this.getWithAuth(
            `/groups`,
            {
                offset: offset,
                limit: limit,
                ...(keyword ? { keyword: keyword } : {}),
            }, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    public async getClubDetails(id: string, token: string): Promise<APIResponse<Datum>> {
        const result = await this.getWithAuth(
            `/groups/${id}`,
            {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    public async getClubMembers(id: string, token: string): Promise<APIResponse<Member[]>> {
        const result = await this.getWithAuth(
            `/groups/${id}/members`,
            {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    public async getFeaturedPost(id: string, token: string): Promise<APIResponse<Post[]>> {
        const result = await this.getWithAuth(
            `/groups/${id}/pins`,
            {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    public async getNewFeeds(id: string, limit, offset, token: string): Promise<APIResponse<Post[]>> {
        const result = await this.getWithAuth(
            `/groups/${id}/feed`,
            {
                limit: limit,
                offset: offset,
            }, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    public async getPostDetails(id: string, token: string): Promise<APIResponse<Post>> {
        const result = await this.getWithAuth(
            `/posts/${id}`,
            {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //add new feed
    public async addNewFeed(clubId, message, medias, token: string): Promise<any> {
        const result = await this.post(
            `/groups/${clubId}/feed`,
            {
                message: message,
                medias: medias
            }, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //edit post
    public async editPost(postId, message, medias, token: string): Promise<any> {
        const result = await this.post(
            `/posts/${postId}`,
            {
                message: message,
                medias: medias,
            }, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //delete post
    public async deletePost(postId, token: string): Promise<any> {
        const result = await this.delete(
            `/posts/${postId}`,
            {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //report post
    public async reportPost(postId, content, token: string): Promise<any> {
        const result = await this.post(
            `/posts/${postId}/report`,
            {
                content: content,
            }, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //pin post
    public async pinPost(postId, token: string): Promise<any> {
        const result = await this.post(
            `/posts/${postId}/pin`,
            {}, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //Unpin post
    public async unPinPost(postId, token: string): Promise<any> {
        const result = await this.delete(
            `/posts/${postId}/pin`,
            {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //Like Post
    public async likePost(id: string, token: string): Promise<APIResponse<Post>> {
        const result = await this.post(
            `/posts/${id}/like`,
            {}, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //Unlike Post
    public async unlikePost(id: string, token: string): Promise<APIResponse<Post>> {
        const result = await this.delete(
            `/posts/${id}/like`,
            {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    public async getCommentList(id: string, limit, offset, token: string): Promise<APIResponse<Comment[]>> {
        const result = await this.getWithAuth(
            `/posts/${id}/comments`,
            {
                limit: limit,
                offset: offset,
            }, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //Like and UnLike Comment
    public async likeComment(idPost, idComment, token: string): Promise<APIResponse<Post>> {
        const result = await this.post(
            `/posts/${idPost}/${idComment}/like`,
            {}, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    public async unlikeComment(idPost, idComment, token: string): Promise<APIResponse<Post>> {
        const result = await this.delete(
            `/posts/${idPost}/${idComment}/like`,
            {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //Comment to a post
    public async commentPost(idPost, message, mediaId, token: string): Promise<APIResponse<Post>> {
        const result = await this.post(
            `/posts/${idPost}/comments`,
            {
                message: message,
                ...(mediaId ? { media_id: mediaId } : {}),
            }, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //Reply a comment
    public async replyComment(idPost, idComment, message, mediaId, token: string): Promise<APIResponse<Post>> {
        const result = await this.post(
            `/posts/${idPost}/comments`,
            {
                comment_id: idComment,
                message: message,
                ...(mediaId ? { media_id: mediaId } : {}),
            }, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }
    // Get Reply comments
    public async getReplyCommentList(postId: string, commentId, limit, offset, token: string): Promise<APIResponse<Comment[]>> {
        const result = await this.getWithAuth(
            `/posts/${postId}/reply/comments`,
            {
                comment_id: commentId,
                limit: limit,
                offset: offset,
            }, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    //Notification 

    public async getNotificationList(limit, offset, token: string): Promise<APIResponse<NotiInterface[]>> {
        const result = await this.getWithAuth(
            `/notification`,
            {
                limit: limit,
                offset: offset,
            }, {
            'Authorization': 'Bearer ' + token,
            'x-lang': xLangConvert(this.xLang),
        }
        )
        return result
    }

    public async updateStateNoti(ids, token: string): Promise<APIResponse<any>> {
        const result = await this.post(
            `/notification/update-state`,
            {
                ids: ids
            }, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

    public async registerNoti(deviceToken, token: string): Promise<APIResponse<any>> {
        const result = await this.post(
            `/notification/register`,
            {
                token: deviceToken
            }, {}, {
            'Authorization': 'Bearer ' + token
        }
        )
        return result
    }

}