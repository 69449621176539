/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ButtonGradient } from "components/Button";
import { login, userSelector } from "components/user/userSlice";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function LoginPage() {
    const { t } = useTranslation();
    const { user, isLoading, isLoggedIn } = useAppSelector(
        userSelector
    );
    const dispatch = useAppDispatch();
    function onLoginButtonClick(e: React.MouseEvent<Element, MouseEvent>) {
        e.preventDefault()

        dispatch(login())
    }
    return (
        <Fragment>
            <Box
                sx={{
                    pt: 5,
                    pb: 5,
                    px: 5.7,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <img src='/assets/icons/logo-login.svg' alt="" />
                </Box>
                <Typography
                    sx={{
                        fontSize: 32,
                        fontWeight: 600,
                    }}
                >
                    {t('Hi there,')}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        mb: 2,
                    }}
                >
                    {t('Welcome to SingSing')}
                </Typography>
                <ButtonGradient onClick={onLoginButtonClick}>
                    {t('Login')}
                </ButtonGradient>

                <ButtonGradient onClick={onLoginButtonClick}>
                    {t('Register')}
                </ButtonGradient>
            </Box>
        </Fragment>
    );
}