/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MenuItem } from './config'

export default function TopMenu({ config }: { config?: MenuItem[] }) {
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                color: 'inherit',
            }}
        >
            {config && config.length > 0 && config.map((item, index) => (
                <Link to={item.link} key={index}
                    style={{
                        color: 'inherit',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            pt: 2,
                            pb: 2,
                            px: 1.6,
                            '& :hover':{
                                background: 'rgba(22, 27, 46, 0.4)',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >

                            <Box
                                sx={{
                                    width: 20,
                                    height: 20,
                                    minWidth: 20,
                                    '& img': {
                                        width: '100%',
                                    },
                                    marginRight: 1.1,
                                }}
                            >
                                {item.icon && <img src={item.icon} alt="" />}
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    color: 'inherit',
                                    fontWeight: 500,
                                    marginRight: '3rem',
                                }}
                                noWrap
                            >
                                {t(item.label)}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                width: 24,
                                height: 24,
                                minWidth: 24,
                                '& img': {
                                    width: '100%',
                                },
                            }}
                        >
                            {<img src='/assets/icons/icon-next.svg' alt="" />}
                        </Box>
                    </Box>

                </Link>
            ))}
        </Box>
    );
}