import { Box } from '@mui/material';
import React from 'react';
import theme from 'theme';
import { NavBar } from '../../NavBar/NavBar';

const MainLayout: React.FC = (): JSX.Element => {
  
  return (
    <>
      <Box
        sx={{
          zIndex: 1000,
          width: '100%',
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          justifyContent: 'center',
          //boxShadow: '0px 3px 6px #00000029',
          position: 'fixed',
        }}
      >
        <NavBar />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: 70,
        }}
      ></Box>
    </>
  );
};

export default MainLayout;