import { Box, Container, Hidden, IconButton } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import Notifications from 'components/Notifications';
import LoginButton from 'components/user/login-button';
import { userSelector } from 'components/user/userSlice';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useWidth from 'utils/useWidth';
import TopMenu from '../Menu/index';
import SearchBar from './components/SearchBar';
import TopMenuMobile from './components/TopMenuMobile';
export function NavBar() {

  const width = useWidth();
  const navigate = useNavigate();
  const [openSearch, setOpenSearch] = useState(false);
  const {isLoggedIn} = useAppSelector(userSelector);

  const path = window.location.pathname;
  console.log(path);

  const handleBack = () => {
    navigate(-1);
  }

  const handleSearch = () => {
    setOpenSearch(true)
  }

  return (
    <Container maxWidth={width}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: '#ffffff',
      }}
    >{openSearch && isLoggedIn ?
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: 70,
          justifyContent: 'space-between',
        }}
      >
        <SearchBar onClose={() => setOpenSearch(false)} />
      </Box>
      :
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: 70,
          justifyContent: 'space-between',
        }}
      >

        <Hidden smUp>
          {path !== "/" ?
            <IconButton
              aria-label="close"
              onClick={handleBack}
              sx={{
                color: '#fff',
                '& img': {
                  width: 18,
                  height: 18,
                }
              }}
            >
              <img src="/assets/icons/icon-back.svg" alt="" />
            </IconButton>
            :
            <IconButton
              aria-label="close"
              onClick={handleSearch}
              sx={{
                color: '#fff',
                '& img': {
                  width: 18,
                  height: 18,
                }
              }}
            >
              <img src="/assets/icons/icon-search.svg" alt="" />
            </IconButton>
          }
        </Hidden>


        <Link to='/'>
          <Box
            sx={{
              height: 35,
              '& img': {
                height: '100%'
              }
            }}
          >
            <img src='/logo_singsing.svg' alt="" />
          </Box>
        </Link>
        <Hidden lgDown>
          <TopMenu />
          <LoginButton />
        </Hidden>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Notifications/>
            <TopMenuMobile />
          </Box>
        </Hidden>
      </Box>

      }

    </Container>
  );
}