/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { userSelector } from "components/user/userSlice";
import AvatarMember from "pages/ClubDetails/components/avatar";
import { Fragment } from "react";
import { BtnEditAvatar, BtnEditCover } from "./Btn";

export default function UserInfo() {
    const { user, isLoggedIn } = useAppSelector(userSelector);
    const size = 80;
    return (
        <Fragment>
            {/* Banner is background */}
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    maxHeight: 300,
                    aspectRatio: '16/9',
                    background: `url(${user.cover})`,
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat'
                }}
            >
                {/* Avatar Here */}
                <Box
                    sx={{
                        px: 2.4,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                width: size,
                                height: size,
                                minWidth: size,
                                margin: .1,
                                borderRadius: '50%',
                                p: .1,
                                background: 'linear-gradient(90deg, #BF26E6 -5.41%, #3C14DA 100%)',
                                '& img': {
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    objectFit: 'cover',
                                }
                            }}
                        >
                            <img src={user.avatar} alt="" />
                            <BtnEditAvatar />
                        </Box>
                        <Box
                            sx={{
                                marginLeft: .8,
                                overflow: 'hidden',
                            }}
                        >
                            <Typography noWrap
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 700,
                                    color: '#fff',
                                }}
                            >
                                {user?.username || ''}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    overflow: 'hidden',
                                }}
                            >
                                <Typography noWrap
                                    sx={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        color: '#fff',
                                    }}
                                >
                                    @{user.email}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <BtnEditCover />
                </Box>
            </Box>
        </Fragment>
    );
}