import { Box, IconButton, SwipeableDrawer, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import TopMenu from "components/Menu";
import UserInfo from "./UserInfo";
import { menuAccountConfig } from "components/Menu/config";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";
import Logout from "./Logout";
import { useAppSelector } from "app/hooks";
import { userSelector } from "components/user/userSlice";

export default function TopMenuMobile() {

    const {isLoggedIn} = useAppSelector(userSelector);
    const [open, setOpen] = useState(false);
    const toggleDrawer = (value) => {
        setOpen(value)
    }

    if(!isLoggedIn){
        return<Box width={30}>&nbsp;</Box>
    }

    return (
        <Fragment>
            <Box
                sx={{
                    width: 30,
                    height: 30,
                    color: '#ffffff',
                    background: 'rgba(19,19,19,.51)',
                    borderRadius: '3px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
                onClick={() => toggleDrawer(true)}
            >
                <MenuIcon />
            </Box>
            <SwipeableDrawer
                anchor='top'
                open={open}
                onOpen={() => toggleDrawer(true)}
                onClose={() => toggleDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper':{
                        background: '#1E2336',
                    },
                }}
            >
                <MenuMobile onClose={() => toggleDrawer(false)} />
            </SwipeableDrawer>
        </Fragment>
    );
}

export function MenuMobile({ onClose }: { onClose: () => void }) {

    const { t } = useTranslation();
    const handleClose = () => {
        onClose();
    }

    return (
        <Fragment>
            <Box
                sx={{
                    minWidth: 330,
                    height: '100vh',
                    background: '#1E2336',
                    backgroundPosition: 'bottom -100px right -100px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Box
                    sx={{
                        padding: 1.6,
                        paddingLeft: 3.8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 0,
                    }}
                >
                    <Box width={30}>&nbsp;</Box>
                    <IconButton
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box>
                    <UserInfo />
                </Box>
                <Box
                    sx={{
                        color: '#B1BBD2',
                        pt: 1.6,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 500,
                            pb: .8,
                            px: 1.6,
                        }}
                    >
                        {t('ACCOUNT')}
                    </Typography>
                    <Box onClick={handleClose}>
                        <TopMenu config={menuAccountConfig} />
                    </Box>

                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 500,
                            pt: 3,
                            pb: .8,
                            px: 1.6,
                        }}
                    >
                        {t('OTHERS')}
                    </Typography>

                    <LanguageSelect />
                    <Box onClick={handleClose}>
                        <Logout />
                    </Box>

                </Box>
            </Box>
        </Fragment>
    );
}